import {notificationService} from '../services'

const initialState = {
  notifications: []
}

export const notification = {
  namespaced: true,
  state: initialState,
  actions: {
    list({commit}) {
      commit('listRequest')

      notificationService.list()
        .then(
          data => {
            commit('listSuccess', data)
          },
          error => {
            commit('listFailure', error)
          }
        );
    },
    update({commit}, {notification}) {
      commit('updateRequest', {notification});

      notificationService.update(notification.pk)
        .then(
          (data) => {
            console.log(data)
            commit('updateSuccess', notification);
          },
          error => {
            commit('updateFailure', error);
          }
        );
    }
  },
  mutations: {
    listRequest() {},
    listSuccess(state, notifications) {
      state.notifications = notifications
    },
    listFailure(state) {
      state.notifications = []
    },
    updateRequest() {},
    updateSuccess(state, notification) {
      let i = state.notifications.indexOf(notification)
      state.notifications.splice(i, 1)
    },
    updateFailure() {},
  }
}
