import {mobileScheduleService} from '../services'

const initialState = {
  schedules: [],
  schedule: null
}

export const mobileSchedule = {
  namespaced: true,
  state: initialState,
  actions: {
    list({commit}) {
      commit('listRequest')

      mobileScheduleService.list()
        .then(
          data => {
            commit('listSuccess', data)
          },
          error => {
            commit('listFailure', error)
          }
        );
    },
    retrieve({commit}, {pk}) {
      commit('retrieveRequest')

      mobileScheduleService.retrieve(pk)
        .then(
          data => {
            commit('retrieveSuccess', data)
          },
          error => {
            commit('retrieveFailure', error)
          }
        );
    },
    update({commit}, {pk, schedule, payload}) {
      commit('updateRequest')

      mobileScheduleService.update(pk, payload)
        .then(
          data => {
            console.debug(data)
            commit('updateSuccess', schedule)
          },
          error => {
            commit('updateFailure', error)
          }
        );
    },
  },
  mutations: {
    listRequest(state) {
      state.schedules = []
    },
    listSuccess(state, schedules) {
      state.schedules = schedules
    },
    listFailure(state) {
      state.schedules = []
    },
    retrieveRequest(state) {
      state.schedule = null
    },
    retrieveSuccess(state, schedule) {
      state.schedule = schedule
    },
    retrieveFailure(state) {
      state.schedule = null
    },
    updateRequest() {},
    updateSuccess(state, schedule) {
      schedule.origin_contract_limits = schedule.contract_limits
    },
    updateFailure() {},
  }
}
