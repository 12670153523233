import Vue from 'vue'
import Router from 'vue-router'
import DriverHomePage from './components/DriverHomePage.vue'
import ManagerHomePage from './components/ManagerHomePage.vue'
import LoginPage from './components/LoginPage.vue'

Vue.use(Router);

export const router = new Router({
  mode: 'history',
  routes: [
    { path: '/', component: DriverHomePage },
    { path: '/manager', component: ManagerHomePage },
    { path: '/login', component: LoginPage },
    // Otherwise redirect to home
    { path: '*', redirect: '/' }
  ]
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login')
  } else {
    next()
  }
});