import Vue from 'vue'
import {authHeader} from '../helpers'
import {userService} from './user.service'

export const mobileScheduleService = {
  list,
  retrieve,
  update,
}

function list() {
  const requestOptions = {
    method: 'GET',
    headers: {'Content-Type': 'application/json', ...authHeader()},
  }

  const url = `${Vue.config.base}mobile_schedules/`
  return fetch(url, requestOptions)
    .then(handleResponse)
}

function retrieve(pk) {
  const requestOptions = {
    method: 'GET',
    headers: {'Content-Type': 'application/json', ...authHeader()},
  }

  const url = `${Vue.config.base}mobile_schedules/${pk}/`
  return fetch(url, requestOptions)
    .then(handleResponse)
}

function update(pk, payload) {
  const requestOptions = {
    method: 'PUT',
    headers: {'Content-Type': 'application/json', ...authHeader()},
    body: JSON.stringify(payload)
  }

  const url = `${Vue.config.base}mobile_schedules/${pk}/`
  return fetch(url, requestOptions)
    .then(handleResponse)
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      if (response.status === 401) {
        // Auto logout if 401 response returned from api
        userService.logout()
        location.reload(true)
      }

      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}