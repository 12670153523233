import {messageService} from '../services'

const initialState = {
  updating: false
}

export const message = {
  namespaced: true,
  state: initialState,
  actions: {
    send({commit}, {message}) {
      commit('createRequest', {message});

      messageService.send(message)
        .then(
          () => {
            commit('createSuccess');
          },
          error => {
            commit('createFailure', error);
          }
        );
    }
  },
  mutations: {
    createRequest(state) {
      state.updating = true
    },
    createSuccess(state) {
      state.updating = false
    },
    createFailure(state) {
      state.updating = false
    },
  }
}