import Vue from 'vue'

export const userService = {
  login,
  logout
}

function login(token) {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({sso_token: token})
  }

  const url = `${Vue.config.base}sso_token/`
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(user => {
      // Login successful if there's a jwt token in the response
      if (user.access) {
        // Store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user))
      }

      return user
    })
}

function logout() {
  // Remove user from local storage to log user out
  localStorage.removeItem('user')
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      if (response.status === 401) {
        // Auto logout if 401 response returned from api
        logout();
        location.reload(true)
      }

      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}