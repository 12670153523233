import {contactService} from '../services'

const initialState = {
  updating: false
}

export const contact = {
  namespaced: true,
  state: initialState,
  actions: {
    update({commit}, {payload}) {
      commit('updateRequest', {payload});

      contactService.update(payload)
        .then(
          () => {
            commit('updateSuccess');
          },
          error => {
            commit('updateFailure', error);
          }
        );
    }
  },
  mutations: {
    updateRequest(state) {
      state.updating = true
    },
    updateSuccess(state) {
      state.updating = false
    },
    updateFailure(state) {
      state.updating = false
    },
  }
}