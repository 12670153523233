<template>
  <div>
    <div class="loading">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">Загрузка...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LoginPage',
  data() {
    return {
      token: ''
    }
  },
  computed: {},
  created() {
    // Reset login status
    this.$store.dispatch('auth/logout')
    this.token = this.$route.query.token
    this.handleLogin()
  },
  methods: {
    handleLogin() {
      const {token} = this;
      const {dispatch} = this.$store;
      if (token) {
        dispatch('auth/login', {token});
      }
    }
  }
}
</script>