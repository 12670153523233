import {contractService} from '../services'

const initialState = {
  activated: false,
  data: {
    email: '',
    fullname: '',
    phone: '',
  }
}

export const contract = {
  namespaced: true,
  state: initialState,
  actions: {
    retrieve({commit}) {
      commit('retrieveRequest')

      contractService.retrieve()
        .then(
          data => {
            commit('retrieveSuccess', data)
          },
          error => {
            commit('retrieveFailure', error)
          }
        );
    },
    notify({commit}, {payload}) {
      commit('notifyRequest', {payload});

      contractService.notify()
        .then(
          () => {
            commit('notifySuccess');
          },
          error => {
            commit('notifyFailure', error);
          }
        );
    }
  },
  mutations: {
    retrieveRequest(state) {
      state.activated = false
      state.data = {
        email: '',
        fullname: '',
        phone: '',
      }
    },
    retrieveSuccess(state, contract) {
      state.activated = contract.activated
      state.data = contract
    },
    retrieveFailure(state) {
      state.activated = false
      state.data = {
        email: '',
        fullname: '',
        phone: '',
      }
    },
    notifyRequest() {},
    notifySuccess() {},
    notifyFailure() {}
  }
}