import {mobileScheduleDateService} from '../services'

const initialState = {
  scheduleDate: null
}

export const mobileScheduleDate = {
  namespaced: true,
  state: initialState,
  actions: {
    reset({commit}) {
      commit('resetRequest')
    },
    retrieve({commit}, {pk, date}) {
      commit('retrieveRequest')

      mobileScheduleDateService.retrieve(pk, date)
        .then(
          data => {
            commit('retrieveSuccess', data)
          },
          error => {
            commit('retrieveFailure', error)
          }
        );
    },
    update({commit}, {pk, date, payload}) {
      commit('updateRequest')

      mobileScheduleDateService.update(pk, date, payload)
        .then(
          data => {
            commit('updateSuccess', data)
          },
          error => {
            commit('updateFailure', error)
          }
        )
    },
    annul({commit}, {pk, date}) {
      commit('annulRequest')

      mobileScheduleDateService.annul(pk, date)
        .then(
          data => {
            commit('annulSuccess', data)
          },
          error => {
            commit('annulFailure', error)
          }
        )
    },
    book({commit}, {pk, date}) {
      commit('bookRequest')

      mobileScheduleDateService.book(pk, date)
        .then(
          data => {
            commit('bookSuccess', data)
          },
          error => {
            commit('bookFailure', error)
          }
        )
    },
    createSlot({commit}, {payload}) {
      commit('createSlotRequest')

      mobileScheduleDateService.createSlot(payload)
        .then(
          data => {
            commit('createSlotSuccess', data)
          },
          error => {
            commit('createSlotFailure', error)
          }
        )
    },
    removeSlot({commit}, {slot}) {
      commit('removeSlotRequest')

      mobileScheduleDateService.removeSlot(slot)
        .then(
          data => {
            console.log(data)
            commit('removeSlotSuccess', slot)
          },
          error => {
            commit('removeSlotFailure', error)
          }
        )
    },
  },
  mutations: {
    resetRequest(state) {
      state.scheduleDate = null
    },
    retrieveRequest(state) {
      state.scheduleDate = null
    },
    retrieveSuccess(state, scheduleDate) {
      state.scheduleDate = scheduleDate
    },
    retrieveFailure(state) {
      state.scheduleDate = null
    },
    updateRequest() {},
    updateSuccess(state, scheduleDate) {
      state.scheduleDate = scheduleDate
    },
    updateFailure() {},
    annulRequest() {},
    annulSuccess(state, scheduleDate) {
      state.scheduleDate = scheduleDate
    },
    annulFailure() {},
    bookRequest() {},
    bookSuccess(state, scheduleDate) {
      state.scheduleDate = scheduleDate
    },
    bookFailure() {},
    createSlotRequest() {},
    createSlotSuccess(state, scheduleSlot) {
      state.scheduleDate.slots.push(scheduleSlot)
    },
    createSlotFailure() {},
    removeSlotRequest() {},
    removeSlotSuccess(state, scheduleSlot) {
      let i = state.scheduleDate.slots.indexOf(scheduleSlot)
      state.scheduleDate.slots.splice(i, 1)
    },
    removeSlotFailure() {},
  }
}
