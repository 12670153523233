<template>
  <div>
    <b-container>
      <b-row class="mt-3">
        <b-col md="4" order-md="1">
          <h4 class="text-left">Информация</h4>
          <b-alert show variant="info" class="mx-auto">
            Дата МШМ блокируется для изменений за 4 календарных дня до наступления даты.
          </b-alert>
          <b-button-group>
            <b-button variant="primary" @click="downloadContracts">
              Скачать список ТС к МШМ
            </b-button>
          </b-button-group>
          <b-button-group class="mt-3">
            <b-button variant="primary" target="_blank" href="docs/Инструкция модуля МШМ в Мой АЛД.pdf">
              Инструкция модуля МШМ в Мой АЛД
            </b-button>
          </b-button-group>
        </b-col>
        <b-col md="4" order-md="2">
          <h4 class="text-center">Календари</h4>
          <b-alert :show="!mobileSchedule.schedules.length" variant="secondary" class="mx-auto">
            В системе не зарегистрировано активных календарей мобильного шиномонтажа.
          </b-alert>
          <div v-for="schedule in mobileSchedule.schedules" :key="schedule" class="text-center">
            <b-card
                :header="schedule.name"
                :title="schedule.period_name"
                :footer="schedule.supplier_name"
                class="mx-auto mb-3"
            >
              <b-card-sub-title class="mb-2">
                С {{ schedule.period_date_start }} по {{ schedule.period_date_end }}
              </b-card-sub-title>
              <b-card-text>
                <div role="group">
                  <label>Количество контрактов для МШМ:</label>
                  <b-input-group class="mt-3">
                    <b-form-input
                        v-model="schedule.contract_limits"
                        type="number"
                        v-on:change="schedule.changed = true"
                    >
                    </b-form-input>
                    <b-input-group-append>
                      <b-button
                          href="#"
                          variant="primary"
                          @click="saveSchedule(schedule)"
                          :disabled="!isScheduleChanged(schedule)"
                      >Сохранить
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </div>
              </b-card-text>
              <b-list-group flush>
                <span>Минимальное количество контрактов для МШМ: {{schedule.min_slots}}</span>
                <b-list-group-item>
                  <b-button
                      href="#"
                      variant="info"
                      @click="onScheduleClick(schedule)"
                      :disabled="isScheduleDisabled(schedule)"
                  >Расписание шиномонтажа
                  </b-button>
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </div>
        </b-col>
        <b-col md="4" order-md="3">
          <h4 class="text-right">Уведомления</h4>
          <b-alert show variant="info" class="mx-auto">
            При возникновении нештатных вопросов, просим обратиться в АЛД <a href="mailto:ru.service.moscow@aldautomotive.com">ru.service.moscow@aldautomotive.com</a>.
          </b-alert>
          <b-alert
            v-for="message in notification.notifications"
            :key="message"
            @dismissed="readMessage(message)"
            dismiss-label="Прочитано"
            variant="info"
            dismissible
            show
          >
            {{ message.text }}
          </b-alert>
        </b-col>
      </b-row>
    </b-container>
    <b-modal
        id="mobile-schedule-date"
        title="Календарь"
        ok-title="Закрыть"
        ok-only
        scrollable
        size="xl"
        @ok="onScheduleOk"
        @cancel="onScheduleClose"
        @close="onScheduleClose">
      <div>
        <b-row>
          <b-col cols="5">
            <b-row>
              <b-col>
                <b-calendar
                    v-if="activeSchedule"
                    @selected="onSelected(activeSchedule.pk, $event)"
                    :date-disabled-fn="dateDisabled"
                    :date-info-fn="dateClass"
                    locale="ru-RU"
                    block
                    :min="getDate(activeSchedule.period_date_start)"
                    :max="getDate(activeSchedule.period_date_end)"></b-calendar>
              </b-col>
            </b-row>
            <b-row class="mt-3" v-if="mobileScheduleDate.scheduleDate">
              <b-col>
                <b-button-toolbar>
                  <b-button-group>
                    <b-button v-if="bookEnabled" variant="primary" @click="bookScheduleDate">
                      Забронировать
                    </b-button>
                    <b-button v-if="annulEnabled" variant="primary" @click="annulScheduleDate">
                      Отменить бронирование
                    </b-button>
                  </b-button-group>
                </b-button-toolbar>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <div v-if="mobileScheduleDate.scheduleDate">
              <b-row>
                <b-col>
                  <b-alert show variant="info">
                    Мин. количество слотов: {{ mobileScheduleDate.scheduleDate.min_slots }}
                    <br/>
                    Макс. количество слотов: {{ mobileScheduleDate.scheduleDate.max_slots }}
                    <br/>
                    Слотов занято: {{ mobileScheduleDate.scheduleDate.slots.length }}
                  </b-alert>
                </b-col>
              </b-row>
              <b-tabs content-class="mt-3">
                <b-tab title="Условия" active>
                  <b-row>
                    <b-col>
                      <b-form-group
                          label="Адрес проведения МШМ/Контактное лицо/Особые условия"
                          label-for="input-mobile_contracts_day_notes"
                          class="mb-0">
                        <b-form-textarea
                            id="input-mobile_contracts_day_notes"
                            v-model="mobileScheduleDate.scheduleDate.notes"
                            rows="4"
                            max-rows="4"
                            :disabled="readOnly"
                        ></b-form-textarea>
                      </b-form-group>
                      <div class="mt-3">
                        <b-button variant="primary" @click="saveScheduleDate" :disabled="readOnly">Сохранить
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </b-tab>
                <b-tab title="Слоты">
                  <b-row>
                    <b-col>
                      <b-button-toolbar>
                        <b-button-group class="mx-1">
                          <b-button variant="primary" @click="downloadScheduleDate">Сохранить в файл</b-button>
                        </b-button-group>
                      </b-button-toolbar>
                    </b-col>
                  </b-row>
                  <b-row class="mt-3">
                    <b-col>
                      <vue-typeahead-bootstrap
                          placeholder="Номер контракта или автомобиля"
                          v-model="query"
                          :data="contracts"
                          :minMatchingChars="2"
                          :serializer="lookupContractSerializer"
                          @hit="selectedContract = $event"
                          @input="lookupContract"
                          :disabled="readOnly"
                      />
                    </b-col>
                    <b-col cols="4">
                      <b-button variant="primary" @click="slotAppend" :disabled="slotAddDisabled">Добавить слот
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row class="mt-3" v-for="slot in mobileScheduleDate.scheduleDate.slots" :key="slot">
                    <b-col>
                      <b-input-group>
                        <b-form-input :value="slot.contract_number + ' (' + slot.contract_id + ')'"
                                      disabled></b-form-input>
                        <b-input-group-append>
                          <b-button variant="danger" @click="slotRemove(slot)" :disabled="readOnly">Удалить</b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-tab>
                <b-tab title="Загрузка из файла" class="mt-3">
                  <b-row>
                    <b-col>
                      <b-button-toolbar>
                        <b-button-group class="mx-1">
                          <b-button variant="primary" @click="downloadScheduleDateExample">Скачать шаблон</b-button>
                        </b-button-group>
                      </b-button-toolbar>
                    </b-col>
                  </b-row>
                  <b-row class="mt-3">
                    <b-col>
                      <b-input-group>
                        <div class="custom-file">
                          <input type="file" ref="file" class="custom-file-input" id="inputFile"
                                v-on:change="handleFileUpload" aria-describedby="inputFileAddon" :disabled="readOnly">
                          <label class="custom-file-label" for="inputFile">{{ fileLabel }}</label>
                        </div>
                        <b-input-group-append>
                          <b-button variant="primary" id="inputFileAddon" :disabled="readOnly" v-on:click="submitFile()">Загрузить
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                  </b-row>
                  <b-row class="mt-3">
                    <b-col>
                      <b-alert show variant="info" class="mx-auto">
                        При заполнении файла необходимо указание полного гос. номера с регионом, без пробелов, латиницей.
                      </b-alert>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { mapState } from 'vuex'
import { authHeader } from '../helpers'
import Vue from 'vue'

export default {
  name: 'ManagerHomePage',
  data() {
    return {
      loading: false,
      activeSchedule: null,
      file: '',
      fileLabel: 'Загрузка слотов из файла',
      // typeahead params
      query: '',
      selectedContract: null,
      contracts: [],
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    slotAddDisabled() {
      if (this.selectedContract) {
        return this.mobileScheduleDate.scheduleDate.slots.length >= this.mobileScheduleDate.scheduleDate.max_slots
          && this.readOnly
      }
      return true
    },
    annulEnabled() {
      return this.mobileScheduleDate.scheduleDate &&
          // Слот не создан и не отменен
          this.mobileScheduleDate.scheduleDate.status !== 'created' &&
          this.mobileScheduleDate.scheduleDate.status !== 'canceled' &&
          this.availableDate()
    },
    bookEnabled() {
      /* Доступность кнопки Забронировать */
      return this.mobileScheduleDate.scheduleDate && (
          // Слот создан, либо отменен
          this.mobileScheduleDate.scheduleDate.status === 'created' ||
          this.mobileScheduleDate.scheduleDate.status === 'canceled'
      ) && this.availableDate()
    },
    readOnly() {
      /* Блокировка интерфейса редактирования слотов */
      return !this.mobileScheduleDate.scheduleDate ||
          this.mobileScheduleDate.scheduleDate.status !== 'booked' ||
          !this.availableDate()
    },
    ...mapState(['mobileSchedule', 'mobileScheduleDate', 'notification'])
  },
  created() {
    this.handleLoad()
  },
  methods: {
    isEmbeded() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },
    isScheduleChanged(schedule) {
      return schedule.contract_limits !== schedule.origin_contract_limits
    },
    isScheduleDisabled(schedule) {
      return this.isScheduleChanged(schedule) || schedule.min_slots > schedule.contract_limits
    },
    handleFileUpload() {
      if (this.$refs.file.files.length > 0) {
        this.file = this.$refs.file.files[0]
        this.fileLabel = this.file.name
      } else {
        this.file = ''
        this.fileLabel = 'Загрузка слотов из файла'
      }
    },
    availableDate() {
      // За 4 дня до МШМ календарь блокируется от изменений
      if (!this.mobileScheduleDate.scheduleDate) {
        return false
      }
      const scheduleDate = Date.parse(this.mobileScheduleDate.scheduleDate.date)
      const today = Date.now()
      const delta = (scheduleDate - today) / 1000 / 3600 / 24
      return delta >= 4
    },
    handleLoad() {
      const {dispatch} = this.$store
      dispatch('mobileSchedule/list')
      dispatch('notification/list')
      setInterval(() => dispatch('notification/list'), 30000)
    },
    handleRetrieve(pk, date) {
      const {dispatch} = this.$store
      dispatch('mobileScheduleDate/retrieve', {pk, date})
      this.fileLabel = 'Загрузка слотов из файла'
    },
    submitFile() {
      const pk = this.activeSchedule.pk
      const date = this.mobileScheduleDate.scheduleDate.date
      const url = `${Vue.config.base}mobile_schedules/${pk}/dates/${date}/upload/`

      let formData = new FormData()
      formData.append('file', this.file)
      const self = this
      Vue.axios.post(url,
          formData,
          {
            headers: {
              'Content-Disposition': `attachment; filename=upload_slots.xlsx`,
              ...authHeader()
            }
          }
      ).then(function () {
        setTimeout(() => {
          // Перезагрузка данных для
          self.handleRetrieve(self.activeSchedule.pk, self.mobileScheduleDate.scheduleDate.date)
        })
        alert('Файл поставлен в очередь на загрузку')
      }).catch(function () {
        console.log('FAILURE!')
      })
    },
    onSelected(pk, ymd) {
      this.query = ''
      this.handleRetrieve(pk, ymd)
    },
    onScheduleClick(schedule) {
      this.activeSchedule = schedule
      const {dispatch} = this.$store
      dispatch('mobileSchedule/retrieve', {pk: schedule.pk})
      dispatch('mobileScheduleDate/reset')

      this.$bvModal.show('mobile-schedule-date')
    },
    onScheduleOk() {
      this.activeSchedule = null
    },
    onScheduleClose() {
      this.activeSchedule = null
    },
    dateDisabled(ymd) {
      return this.activeSchedule && this.activeSchedule.excluded.indexOf(ymd) > -1
    },
    dateClass(ymd) {
      if (this.mobileSchedule.schedule) {
        if (this.mobileSchedule.schedule.dates.indexOf(ymd) > -1) {
          return 'table-info'
        }
      }
      return ''
    },
    getDate(str) {
      const items = str.split('.')
      return new Date(items[2], items[1] - 1, items[0])
    },
    downloadScheduleDate: debounce(function () {
      let anchor = document.createElement('a')
      document.body.appendChild(anchor)

      const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json', ...authHeader()},
      }
      const pk = this.activeSchedule.pk
      const date = this.mobileScheduleDate.scheduleDate.date
      const url = `${Vue.config.base}mobile_schedules/${pk}/dates/${date}/download/`
      fetch(url, requestOptions)
        .then(response => response.blob())
        .then(blob => {
          let objectUrl = window.URL.createObjectURL(blob)

          anchor.href = objectUrl
          anchor.download = `moi_ald_tyres_${date}.xlsx`
          anchor.click()

          window.URL.revokeObjectURL(objectUrl);
        })
    }, 1000),
    downloadScheduleDateExample: debounce(function () {
      let anchor = document.createElement('a')
      document.body.appendChild(anchor)

      const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json', ...authHeader()},
      }
      const pk = this.activeSchedule.pk
      const date = this.mobileScheduleDate.scheduleDate.date
      const url = `${Vue.config.base}mobile_schedules/${pk}/dates/${date}/download_example/`
      fetch(url, requestOptions)
        .then(response => response.blob())
        .then(blob => {
          let objectUrl = window.URL.createObjectURL(blob)

          anchor.href = objectUrl
          anchor.download = `moi_ald_tyres_example.xlsx`
          anchor.click()

          window.URL.revokeObjectURL(objectUrl);
        })
    }, 1000),
    lookupContract: debounce(function() {
      const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json', ...authHeader()},
      }
      const url = `${Vue.config.base}manager_contracts/?search=${this.query}&schedule=${this.activeSchedule.pk}`
      fetch(url, requestOptions)
        .then(response => {
          return response.json()
        })
        .then(data => {
          this.contracts = data
        })
    }, 500),
    lookupContractSerializer(item) {
      return `${item.number} / ${item.customer_name} (${item.pk})`
    },
    annulScheduleDate: function () {
      const data = {
        pk: this.activeSchedule.pk,
        date: this.mobileScheduleDate.scheduleDate.date,
      }
      const {dispatch} = this.$store
      dispatch('mobileScheduleDate/annul', data)
        .then(() => {
          console.log('Success')
        })
        .catch((error) => {
          console.log(error)
          alert('Произошла непредвиденная ситуация, попробуйте повторить позднее')
        })
    },
    bookScheduleDate: function () {
      const data = {
        pk: this.activeSchedule.pk,
        date: this.mobileScheduleDate.scheduleDate.date,
      }
      const {dispatch} = this.$store
      dispatch('mobileScheduleDate/book', data)
        .then(() => {
          console.log('Success')
        })
        .catch((error) => {
          console.log(error)
          alert('Произошла непредвиденная ситуация, попробуйте повторить позднее')
        })
    },
    downloadContracts: debounce(function () {
      let anchor = document.createElement('a')
      document.body.appendChild(anchor)

      const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json', ...authHeader()},
      }
      const url = `${Vue.config.base}contracts/download_mobile/`
      fetch(url, requestOptions)
        .then(response => response.blob())
        .then(blob => {
          let objectUrl = window.URL.createObjectURL(blob)

          anchor.href = objectUrl
          anchor.download = `mobile_contracts.xlsx`
          anchor.click()

          window.URL.revokeObjectURL(objectUrl);
        })
    }, 1000),
    readMessage: function (notification) {
      const {dispatch} = this.$store
      dispatch('notification/update', {notification})
    },
    saveSchedule: function(schedule) {
      const data = {
        pk: schedule.pk,
        schedule,
        payload: {
          contract_limits: schedule.contract_limits,
        }
      }
      const {dispatch} = this.$store
      dispatch('mobileSchedule/update', data)
        .then(() => {
          console.log('Success')
        })
        .catch((error) => {
          console.log(error)
          alert('Произошла непредвиденная ситуация, попробуйте повторить позднее')
        })
    },
    saveScheduleDate: function() {
      const data = {
        pk: this.activeSchedule.pk,
        date: this.mobileScheduleDate.scheduleDate.date,
        payload: {
          notes: this.mobileScheduleDate.scheduleDate.notes,
        }
      }
      const {dispatch} = this.$store
      dispatch('mobileScheduleDate/update', data)
        .then(() => {
          console.log('Success')
        })
        .catch((error) => {
          console.log(error)
          alert('Произошла непредвиденная ситуация, попробуйте повторить позднее')
        })
    },
    slotAppend() {
      if (this.mobileScheduleDate.scheduleDate) {
        const payload = {
          schedule: this.activeSchedule.pk,
          date: this.mobileScheduleDate.scheduleDate.date,
          contract: this.selectedContract.pk
        }
        const {dispatch} = this.$store
        dispatch('mobileScheduleDate/createSlot', {payload})
          .then(() => {
            this.query = ''
            this.selectedContract = null
          })
      }
    },
    slotRemove(slot) {
      const {dispatch} = this.$store
      dispatch('mobileScheduleDate/removeSlot', {slot})
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card {
  border-radius: 6px;
  color: #1f4d79;
  /*border: 1px solid #1f4d79;*/
  max-width: 450px;
  margin-left: 15px;
  margin-right: 15px;
}

a.btn, button.btn-primary {
  font-weight: 700;
  font-size: 1rem !important;
  color: #fff;
  background-color: #1f4d79;
  justify-content: center;
  /*padding: calc(.5em - 1px) 1em;*/
  text-align: center;
  white-space: nowrap;
}
.custom-file-label:after {
  content: "Выбрать";
}
</style>
