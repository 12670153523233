import Vue from 'vue'
import Vuex from 'vuex'

import {auth} from './auth.module'
import {contact} from './contact.module'
import {contract} from './contract.module'
import {message} from './message.module'
import {mobileSchedule} from './mobileSchedule.module'
import {mobileScheduleDate} from './mobileScheduleDate.module'
import {notification} from './notification.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    contact,
    contract,
    message,
    mobileSchedule,
    mobileScheduleDate,
    notification,
  }
})
