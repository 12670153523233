import Vue from 'vue'
import {authHeader} from '../helpers'
import {userService} from './user.service'

export const mobileScheduleDateService = {
  retrieve,
  update,
  annul,
  book,
  createSlot,
  removeSlot,
}

function retrieve(pk, date) {
  const requestOptions = {
    method: 'GET',
    headers: {'Content-Type': 'application/json', ...authHeader()},
  }

  const url = `${Vue.config.base}mobile_schedules/${pk}/dates/${date}/`
  return fetch(url, requestOptions)
    .then(handleResponse)
}

function update(pk, date, payload) {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json', ...authHeader()},
    body: JSON.stringify(payload)
  }

  const url = `${Vue.config.base}mobile_schedules/${pk}/dates/${date}/`
  return fetch(url, requestOptions)
    .then(handleResponse)
}

function annul(pk, date) {
  const requestOptions = {
    method: 'PUT',
    headers: {'Content-Type': 'application/json', ...authHeader()},
  }

  const url = `${Vue.config.base}mobile_schedules/${pk}/dates/${date}/annul/`
  return fetch(url, requestOptions)
    .then(handleResponse)
}

function book(pk, date) {
  const requestOptions = {
    method: 'PUT',
    headers: {'Content-Type': 'application/json', ...authHeader()},
  }

  const url = `${Vue.config.base}mobile_schedules/${pk}/dates/${date}/book/`
  return fetch(url, requestOptions)
    .then(handleResponse)
}

function createSlot(payload) {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json', ...authHeader()},
    body: JSON.stringify(payload)
  }

  const url = `${Vue.config.base}mobile_schedule_slots/`
  return fetch(url, requestOptions)
    .then(handleResponse)
}

function removeSlot(slot) {
  const requestOptions = {
    method: 'DELETE',
    headers: {'Content-Type': 'application/json', ...authHeader()},
  }

  const url = `${Vue.config.base}mobile_schedule_slots/${slot.pk}/`
  return fetch(url, requestOptions)
    .then(handleResponse)
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      if (response.status === 401) {
        // Auto logout if 401 response returned from api
        userService.logout()
        location.reload(true)
      }

      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}