import {userService} from '../services'
import {router} from '../router'

const user = JSON.parse(localStorage.getItem('user'))
const initialState = user
  ? {status: {loggedIn: true}, user}
  : {status: {}, user: null}

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({commit}, {token}) {
      commit('loginRequest', {token});

      userService.login(token)
        .then(
          user => {
            commit('loginSuccess', user);
            if (user.role === 'DRIVER') {
              router.push('/');
            } else {
              router.push('/manager');
            }
          },
          error => {
            commit('loginFailure', error);
          }
        );
    },
    logout({commit}) {
      userService.logout();
      commit('logout');
    }
  },
  mutations: {
    loginRequest(state, user) {
      state.status = {loggingIn: true};
      state.user = user;
    },
    loginSuccess(state, user) {
      state.status = {loggedIn: true};
      state.user = user;
    },
    loginFailure(state) {
      state.status = {};
      state.user = null;
    },
    logout(state) {
      state.status = {};
      state.user = null;
    }
  }
}