<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'App',
  data() {
    return {
      baseUrl: Vue.config.base
    }
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  /*margin-top: 60px;*/
}
body, button, input, select, textarea {
  font-family: 'Source Sans Pro', sans-serif;
}
body {
  background: #eaf3fa;
}
label:not(:last-child) {
  margin-bottom: .5em;
}

label {
  color: #363636;
  display: block;
  font-size: 1rem;
  font-weight: 700;
}
.text-muted {
  font-size: .75rem;
}
</style>
