import Vue from 'vue'

import axios from 'axios'
import BootstrapVue from 'bootstrap-vue'
import VueAxios from 'vue-axios'
import VueGoodWizard from 'vue-good-wizard'
import VueTheMask from 'vue-the-mask'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'

import { router } from './router'
import store from './store'
import App from './App.vue'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.base = process.env.VUE_APP_BASE_URL
Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(VueAxios, axios)
Vue.use(VueGoodWizard)
Vue.use(VueTheMask)
Vue.component('vue-typeahead-bootstrap', VueTypeaheadBootstrap)

new Vue({
	router,
  store,
  render: h => h(App),
}).$mount('#app')