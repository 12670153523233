<template>
  <div>
    <div v-if="loading" class="loading">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">Загрузка...</span>
        </div>
      </div>
    </div>
    <div v-cloak>
      <div class="container">
        <div class="alert alert-warning text-center" role="alert" v-if="!contract.activated">
          По вашему автомобилю ожидается обновление данных. Повторите запрос позднее или обратитесь в офис АЛД.
        </div>
        <vue-good-wizard
            v-if="contract.activated"
            ref="wizard"
            :steps="steps"
            :on-next="nextClicked"
            :on-back="backClicked"
            previous-step-label="Вернуться"
            next-step-label="Продолжить"
            final-step-label="Подтвердить">
          <div slot="page1">
            <div class="alert alert-user-message" role="alert">
              <p class="font-weight-bold text-center">Введите свои данные и узнайте список шинных центров, доступных Вам
                для прохождения шиномонтажа.<br>
                Данный сервис носит исключительно информационный характер.</p>
              <p style="font-size: 0.9em;"><span style="color: #e4032e;">Обращаем внимание, что заполнение формы не является автоматической записью на шиномонтаж.</span><br>
                Воспользуйтесь инструкцией для получения полной информации о процессе прохождения шиномонтажа.</p>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="card">
                  <div class="card-body" ref="form">
                    <div class="form-group">
                      <label for="inputName">Фамилия Имя Отчество</label>
                      <input v-model="name" ref="name" class="form-control" id="inputName" required>
                      <div class="invalid-feedback">
                        Обязательно для заполнения
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="inputPhone">Контактный телефон</label>
                      <div>
                        <the-mask mask="+7 ### ### ## ##" aria-describedby="inputPhoneBlock" type="tel"
                                  v-model="phone" ref="phone" class="form-control" id="inputPhone" required></the-mask>
                      </div>
                      <small id="inputPhoneBlock" class="form-text text-muted">
                        В формате +7 987 654 32 10
                      </small>
                      <div class="invalid-feedback">
                        Обязательно для заполнения
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="inputEmail">E-mail</label>
                      <input v-model="email" ref="email" type="email" class="form-control" id="inputEmail"
                             required>
                      <small id="inputEmailBlock" class="form-text text-muted">
                        Действующий адрес электронной почты
                      </small>
                      <div class="invalid-feedback">
                        Обязательно для заполнения.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card">
                  <div class="card-body">
                    <h2>{{ contract.data.customer_name }}</h2>
                    <h1>{{ contract.data.number }}</h1>
                    <h4 v-if="contract.data.city">Город эксплуатации: <span class="text-capitalize">{{ contract.data.city }}</span></h4>
                  </div>
                </div>
                <div class="card">
                  <div class="card-body">
                    <a target="_blank" href="docs/Инструкция_Шиномонтаж_осень_2022.pdf" class="instruction">Инструкция по
                      сезонной замене шин АЛД&nbsp;Автомотив</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <b-button v-b-modal.feedback class="btn-danger">Сообщить об ошибке</b-button>
            </div>
          </div>
          <div slot="page2">
            <div class="table-responsive">
              <table class="table">
                <tbody>
                <tr>
                  <td>Компания:</td>
                  <td>{{ contract.data.customer_name }}</td>
                </tr>
                <tr>
                  <td>Номер договора:</td>
                  <td>{{ contract.data.id }}</td>
                </tr>
                <tr>
                  <td>Гос. номер автомобиля:</td>
                  <td>{{ contract.data.number }}</td>
                </tr>
                <tr>
                  <td>Фамилия Имя Отчество:</td>
                  <td>{{ name }}</td>
                </tr>
                <tr>
                  <td>Контактный телефон:</td>
                  <td>+7{{ phone }}</td>
                </tr>
                <tr v-if="contract.data.city">
                  <td>Город эксплуатации:</td>
                  <td class="text-capitalize">{{ contract.data.city }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="alert alert-user-message" role="alert">
              <p>Ниже Вам доступен список станций обслуживания для прохождения сезонной замены шин. Обращаем внимание,
                  что данный список применим исключительно для Вашего автомобиля.</p>
              <p>При нажатии «Продолжить», по адресу {{ email }} будет выслано письмо с документом, содержащим
                  список, доступных Вам шинных центров.</p>
            </div>
            <div class="alert alert-warning" role="alert" v-if="showDisclaimer">
                Необходимо дополнительное согласование с управляющим автопарка в Вашей компании и АЛД&nbsp;Автомотив.
            </div>
            <div class="alert alert-warning" role="alert" v-if="showMobile">
                Шиномонтаж по вашему т/с проводится централизованно. Обратитесь к вашему управляющему автопарком.
            </div>
            <div class="alert alert-warning" role="alert" v-if="noTyreFittings">
                По вашему автомобилю нет данных. Обратитесь в офис АЛД.
            </div>
            <div id="accordion" v-if="showTireServices">
              <div class="card">
                <div class="card-header" id="headingOne">
                  <h5 style="color: red;">Плановое начало шиномонтажа в вашем регионе: {{ replaceStartDate }}</h5>
                  <h5 class="mb-0">
                    <b-button v-b-toggle.collapse-tire-services variant="link">Доступные шинные центры</b-button>
                  </h5>
                </div>
                <b-collapse id="collapse-tire-services" class="mt-2">
                  <div class="card-body">
                    <table class="table table-hover table-float-rows select-tyre-fitting">
                      <thead>
                      <tr>
                        <th scope="col">Наименование</th>
                        <th scope="col">Телефон</th>
                        <th scope="col">Сайт</th>
                        <th scope="col" colspan="2">Адрес</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="tire_service in contract.data.tire_services" :key="tire_service">
                        <td>
                          {{ tire_service.name }}
                        </td>
                        <td>{{ tire_service.phones }}</td>
                        <td>
                          <a v-if="tire_service.site" v-bind:href="tire_service.site" target="_blank">
                            {{ tire_service.site }}
                          </a>
                        </td>
                        <td @click="openMap(tire_service)" class="cls-pointer">
                          <span class="oi oi-map-marker"></span>
                        </td>
                        <td>
                          <span v-if="tire_service.city">
                            {{ tire_service.city }},
                          </span>{{ tire_service.address }}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
        </vue-good-wizard>
        <b-modal id="notify-sent" ref="notify-sent" ok-only>
          <p> На указанную Вами почту был отправлен список доступных Вам шинных центров для записи на
            шиномонтаж.</p>
          <p style="color: red;">Обращаем внимание, что заполнение формы не является автоматической записью на
            шиномонтаж.</p>
          <p>Вам необходимо выбрать шинный центр из списка и <span style="color: red;">самостоятельно</span>
            связаться с ним для записи.</p>
        </b-modal>
        <b-modal id="feedback" title="Сообщить об ошибке" ok-title="Отправить" cancel-title="Закрыть" @ok="handleSendMessage">
          <div class="form-group">
            <label for="imessage">Сообщение</label>
            <textarea class="form-control" v-model="message" required id="imessage" rows="3" maxlength="2000"
                      ref="message"></textarea>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'DriverHomePage',
  data() {
    return {
      loading: false,
      steps: [
        {
          label: 'Ввод данных',
          slot: 'page1',
        },
        {
          label: 'Получить список центров',
          slot: 'page2',
        },
      ],
      name: '',
      phone: '',
      email: '',
      message: '',
    }
  },
  computed: {
    showDisclaimer: function() {
      return this.contract.data
        && (
          this.contract.data.type === 'LTN'
          || this.contract.data.type === 'PAR'
          || this.contract.data.type === ''
        )
        && !this.contract.data.is_stored
    },
    showTireServices: function() {
      return this.contract.data && this.contract.data.tire_services && this.contract.data.tire_services.length && !this.contract.data.is_mobile
    },
    showMobile: function() {
      return this.contract.data && this.contract.data.is_mobile
    },
    noTyreFittings: function() {
      return !this.contract.data || !this.contract.data.tire_services || !this.contract.data.tire_services.length
    },
    replaceStartDate: function() {
      if (this.contract.data.tire_services && this.contract.data.tire_services.length) {
        return this.contract.data.tire_services[0].replace_start_date
      }
      return ''
    },
    ...mapState(['auth', 'contract'])
  },
  created() {
    this.handleRetrieve()
  },
  watch: {
    'contract.data'(data) {
      this.email = data.email
      this.name = data.fullname
      this.phone = data.phone
    },
  },
  methods: {
    handleRetrieve() {
      const {dispatch} = this.$store
      dispatch('contract/retrieve')
    },
    handleSendMessage() {
      const {dispatch} = this.$store
      dispatch('message/send', {message: this.message})
    },
    showNotifySent() {
      this.$refs['notify-sent'].show()
    },
    openMap: function (tyreFitting) {
      console.log(tyreFitting)
      // axios.get('api/geo/' + tyreFitting.id)
      //   .then(
      //     function(response) {
      //       window.open('https://maps.yandex.ru/?text=' + response.data.lat + ',' + response.data.long, '_blank')
      //     }
      //   );
    },
    nextClicked: function (currentPage) {
      if (currentPage === 0) {
        const valid = this.email && this.name && this.phone
        if (!valid) {
          this.$refs.form.classList.add('was-validated')
          return false
        }
        this.saveContact()
      } else if (currentPage === this.steps.length - 1) {
        this.notifyUser()
        // Отправка уведомления на последнем шаге
      }
      return true
    },
    backClicked: () => true,
    saveContact: function() {
      const payload = {
        email: this.email,
        fullname: this.name,
        phone: this.phone,
      }
      const {dispatch} = this.$store
      dispatch('contact/update', {payload})
        .then(() => {
          console.log('Success')
        })
        .catch((error) => {
          console.log(error)
          alert('Произошла непредвиденная ситуация, попробуйте повторить позднее')
        })
    },
    notifyUser: function() {
      const payload = {}
      const {dispatch} = this.$store
      dispatch('contract/notify', {payload})
        .then(() => {
          this.showNotifySent()
          console.log('Success')
        })
        .catch((error) => {
          console.log(error)
          alert('Произошла непредвиденная ситуация, попробуйте повторить позднее')
        })
    },
  }
}
</script>


<style>
#app .wizard__body__step {
  padding: 40px;
}

#app .wizard__step__label {
  color: #1f4d79;
}

#app .wizard__step .wizard__step__indicator {
  background: #d9d9d9;
}

#app .wizard__step.active .wizard__step__indicator {
  background: #1f4d79;
}

#app .wizard__body__actions a.wizard__back {
  background-color: #fff;
  font-weight: 400;
  color: #363636;
  width: 140px;
}

#app .wizard__body__actions a.wizard__next {
  background-color: #3273dc;
  font-weight: 400;
  width: 140px;
}

#app .vgw-icon.vgw-prev::after {
  border-top: 2px solid #363636;
  border-left: 2px solid #363636;
}

#app a:hover {
  text-decoration: none;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.alert-user-message {
  background-color: #e0e7ec;
}

.card {
  margin-bottom: 20px;
}

.instruction {
  background: url("../assets/instruction.png") no-repeat right;
  height: 60px;
  display: inline-block;
  background-size: contain;
  padding-right: 60px;
}
</style>
